import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { Link } from '@/components/WithTracking/Link';
import { LOHPPageSections, NavTrackActions } from '@/constants/analytics';
import { LINK_TYPE } from '@/constants/global';
import { useFormatLink } from '@/hooks/useFormatLink';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { NAMESPACE_LOHP } from '@/translations/namespaces';

export const styles = {
  resourcesGrid: ({ breakpoints, spacing }: Theme) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: spacing(17.5),
    gridGap: spacing(2),
    overflow: 'hidden',
    alignItems: 'center',
    [breakpoints.up('xl')]: {
      gridTemplateRows: spacing(35),
    },
    [breakpoints.between('lg', 'xl')]: {
      gridTemplateRows: spacing(26.25),
    },
    [breakpoints.between('sm', 'md')]: {
      gridTemplateRows: spacing(14),
    },
    [breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto auto',
    },
  }),
  resourcesCard: {
    position: 'relative',
    objectFit: 'cover',
    justifySelf: 'center',
    overflow: 'hidden',
    maxHeight: '100%',
    minWidth: '100%',
  },
  resourcesCardImage: {
    display: 'block',
    width: '100%',
    height: '100%',
    filter: 'brightness(60%)',
    transition: 'filter 0.2s',
    alignSelf: 'start',
    '&:hover': {
      filter: 'brightness(80%)',
    },
  },
  resourcesOverlayText: ({ spacing }: Theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(calc(-50% + 16px / 2), calc(-50% - 16px / 2))',
    color: '#fff',
    textAlign: 'center',
    lineHeight: spacing(4.5),
    pointerEvents:
      'none' /* To ignore the hover event on the text (for the background image brightness transition to be consistent) */,
    width: '80%',
  }),
};

export const Resources = () => {
  const { t } = useTranslation([NAMESPACE_LOHP]);

  const shotListUrl = useFormatLink({
    href: '/explore/the-shot-list',
    linkType: LINK_TYPE.SHUTTERSTOCK_NEXT_PUBLIC,
  });

  const contributorBlogUrl = useFormatLink({
    linkType: LINK_TYPE.CONTRIBUTOR_BLOG,
  });

  return (
    <PageSection value={LOHPPageSections.resources}>
      <Box data-testid="resources-container" sx={{ marginBottom: ({ spacing }) => spacing(12) }}>
        <Typography component="h2" variant="h3">
          {t('contributor_lohp_resources_title')}
        </Typography>
        <Box sx={styles.resourcesGrid}>
          <Box sx={styles.resourcesCard}>
            {/* Don't force localization for YouTube links */}
            <Link
              href="https://www.youtube.com/channel/UCKyVN7fBTaQ3np4WOO7IK9A"
              trackAction={NavTrackActions.youtubeLink}
              target="_blank"
              rel="noopener"
            >
              <Box
                component="img"
                src="https://submit2.shutterstock.com/public/img/lohp/workshops-1cdfe4806c.jpg"
                width="100%"
                height="100%"
                alt="A person in a striped shirt is talking to the camera with their hands raised. There are emoji lins pointing towards and away from the person. The black background has two standing white lights to the side."
                sx={styles.resourcesCardImage}
                loading="lazy"
              />
              <Box sx={styles.resourcesOverlayText}>
                <Typography component="h3" variant="h4">
                  {t('contributor_lohp_resources_workshops')}
                </Typography>
                <Typography variant="body1">{t('contributor_lohp_resources_workshops_sub_text')}</Typography>
              </Box>
            </Link>
          </Box>
          <Box sx={styles.resourcesCard}>
            <Link href={shotListUrl} target="_blank" rel="noopener" trackAction={NavTrackActions.shotListLink}>
              <Box
                component="img"
                src="https://submit2.shutterstock.com/public/img/lohp/shot_list-db4816da68.jpg"
                width="100%"
                height="100%"
                alt="A photographer points a lense into the distance while standing on rock shelf. The colour scheme is black and white. The background is a mist covered mountainous landscape with a cloud covered sky."
                sx={styles.resourcesCardImage}
                loading="lazy"
              />
              <Box sx={styles.resourcesOverlayText}>
                <Typography component="h3" variant="h4">
                  {t('contributor_lohp_resources_shot_list')}
                </Typography>
                <Typography variant="body1">{t('contributor_lohp_resources_shot_list_sub_text')}</Typography>
              </Box>
            </Link>
          </Box>
          <Box sx={styles.resourcesCard}>
            <Link
              href={contributorBlogUrl}
              target="_blank"
              rel="noopener"
              trackAction={NavTrackActions.contributorBlogLink}
            >
              <Box
                component="img"
                src="https://submit2.shutterstock.com/public/img/lohp/blog-f9f6e20530.jpg"
                width="100%"
                height="100%"
                alt="Camera, writing, and computer equipment are laid out neatly and evenly spaced out on a wooden desk. A fake plant adds some colour in the top left corner. "
                sx={styles.resourcesCardImage}
                loading="lazy"
              />
              <Box sx={styles.resourcesOverlayText}>
                <Typography component="h3" variant="h4">
                  {t('contributor_lohp_resources_blog')}
                </Typography>
                <Typography variant="body1">{t('contributor_lohp_resources_blog_sub_text')}</Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </PageSection>
  );
};
